import React, { useEffect } from "react";
import {Redirect, Route, Switch } from "react-router-dom";
import Landing from "./sections/landing";
import Terms from "./sections/terms";
import Reservation from "./sections/reservation";
import Approved from "../global-elements/approved2";
import Declined from "../global-elements/declined";
import { useStoreState } from "easy-peasy";
import DeclinedTemporal from "../global-elements/declinedTemporal";
import "../../../assets/dm/reservation.css";
import Error404 from "../../systempages/error404/Error404";
import GrandBankPage from "../global-elements/grandbank/grandbankPage";
import Flinks from '../flinks/flink';

function Layout() {
 
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const termsStep = useStoreState(state => state.displayPrequalTerms);
  const resultStep = useStoreState(state => state.displayResult);

  useEffect(() => { document.querySelector("title").textContent = `${brandSettings.ProductName} - Mastercard`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Switch>
      {/* Landing page No form */}
      <Route path="/" component={() => <Landing />} exact />
      {/* Reservation Page */}
      <Route path="/reservation" component={() => <Reservation />} exact />
      {/* Terms page No form */}
      <Route path="/terms" component={() => (termsStep) ? <Terms /> : <Redirect to={"/"+window.location.search} />} exact />
      {/* Approved Page */}
      <Route path="/approved" component={() => (resultStep) ? <Approved /> : <Redirect to={"/"+window.location.search} />} exact />
      {/* Terms page No form */}
      <Route
        path="/declined"
        component={() => (resultStep) ? <Declined /> : <Redirect to={"/"+window.location.search} />}
        exact
      />
      <Route path="/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/"+window.location.search} />} exact/>
      <Route path="/pendinfo" component={() => (resultStep) ? <DeclinedTemporal /> : <Redirect to={"/"+window.location.search} />} exact />
      <Route path="/link-account" component={() => (resultStep) ? <Flinks /> : <Redirect to={"/" + window.location.search} />} exact />
      <Route  component={() => <Error404 />}  />
    </Switch>
  );
}

export default Layout;
