
export default {
  landing: {
    block1: {
      title: "TWO IS BETTER THAN ONE",
      descriptionFirstLine: "Get a Second Credit Card!",
      descriptionSecondLine:
        "You’ve been pre-approved for a second Mastercard®!",
      reservationNumber: "Enter your Reservation Number",
      securityNumber: "Your Social Security Number",
      tooltipIncome : "Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.",
    },
    block2: {
      title: "Your Initial Credit Limit Doubles",
      description:
        "After making your first six monthly minimum payments on time, your initial credit limit will double. Shopping, dining, even travel are all in your future with your new {ProductName}® Mastercard.",
    },
    secureSite: {
      title: "This is a secure site.",
      firstLine: "Ways we protect you and your information:",
      secondLine:
        "We use 256-bit encryption technology to protect your personal information when you’re using our site or apps. You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and you see a lock symbol in your web browser.",
    },
  },
  terms: {
    block1: {},
    block2: {},
  },
};
