import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import marketing from '../../../services/marketing';
import OtherOptions from './otherOptions'
import GrandBankAdd from './grandbank/grandBankEntryPoint2';
import PixelsService from '../../../services/pixels';
import Parser from 'react-html-parser';
import AudioEyeSupport from '../../../services/audioEyeSupport';
import { useHistory } from 'react-router-dom';

function Declined2() {

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const setting = useStoreState(state => state.siteSettingsModel);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const showOnDeclined = useStoreState(state => state.siteSettingsModel.showOnDeclined);
    const webAssetsBaseUrlSettings = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const text = useStoreState(state => state.DeclinedText);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const utms = new marketing()
    const ada = new AudioEyeSupport();
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const PixelService = new PixelsService();
    const setShowHeader = useStoreActions(action => action.siteSettingsModel.setShowHeader);
    const WaterfallEvents = { "DM": 9, "PREQUAL": 4, "CFC": 16, "STANDALONE": 22, "CS": 28 };
    const history = useHistory();
    const [paymentClick, setPaymentClick] = useState(false)
    const redirectLink = 'https://offer.yourfitcard.com/payment/'

    useEffect(() => {
        if(applicationResult.decline_message.includes("You may click <a href='javascript:void(0);'  class='redirect_pendfee' onclick='GotoPaymentPage()'>here</a> to pay for the processing fee.")){
            setPaymentClick(true)
        }
        setShowHeader(true)
        if (alResult === false) {
            //getGACode(Pixels);
            PixelService.pageView("/declined").event("google", Flow, "declined");
            utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
            ada.setDYContext({ type: "OTHER", data: ["Declined", applicationResult.ref_number] });
            ada.sendDYEvent({ name: 'Declined' })
            if (typeof window.nid === 'function') {
                window.nid('closeSession')
            }
            getRoutingSettings({ Url: setting.domain, Route: "/declined" });
            setAlResult(true)
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.go(1);
            }
        };
    }, [history]);

    function getNoReplyEmail() {
        if (productSettings.NoReplyEmail) {
            return productSettings.NoReplyEmail
        }
        if (brandSettings.ProductName === "Revel") {
            return "noreply@revelcard.com";
        } else {
            return "noreply@your" + brandSettings.ProductName.toLowerCase() + "card.com";
        }
    }

    function getDeclinedMessage() {
        if (applicationResult.decline_message === "" || applicationResult.decline_message === null) {
            if (text.defaultText.flags.find(Text => Text.flag === applicationResult.result.toUpperCase())) {
                return text.defaultText.flags.find(Text => Text.flag === applicationResult.result.toUpperCase())["text"];
            } else {
                return text.defaultText.flags.find(Text => Text.flag === "")["text"];
            }
        }
        let temporal = applicationResult.decline_message;
        // eslint-disable-next-line no-script-url  
        
        

        return temporal.replace("You may click <a href='javascript:void(0);'  class='redirect_pendfee' onclick='GotoPaymentPage()'>here</a> to pay for the processing fee.", "");
    }

    // eslint-disable-next-line no-unused-vars 
   
    return (
        <div className="resultpage">
            <div className="container">
                {
                    // eslint-disable-next-line  no-whitespace-before-property
                    applicationResult.ref_number !== "" && applicationResult.ref_number !== null ?
                        <div className="row" >
                            <div className="col-lg-12 text-center">
                                <h4 style={{ "color": "#f56928" }}>Reference #: {applicationResult.ref_number}</h4>
                            </div>
                        </div>
                        :
                        <></>
                }
                <div className='row'>
                    <div className='card gbBox'>
                        <div className='card-body no-bottom-margin  row' style={{ "marginBottom": "0 !important" }}>
                            <div className="col-lg-12 congratulations">
                                <h2>Thank you for applying</h2>
                            </div>
                            <div className={brandSettings.ProductName === "Revel" ? "col-lg-4 text-center" : "col-lg-4"}><img style={{ width: (brandSettings.ProductName === "Revel" ? "80%" : "") }} className="col-lg-12" src={(brandSettings.ProductName === "Surge") ? ((Flow === "DM") ? webAssetsBaseUrlSettings.CdnImage + "Surge/all-colors.png" : webAssetsBaseUrlSettings.CdnImage + "Surge/card-silver.png") : brandSettings.CardImage} alt={brandSettings.ProductName} /></div>
                            <div className="col-lg-8 pt-0 d-flex align-items-center">
                                <p className='text-left result-message-declined2'>
                                    <strong>Thank you for your interest in {brandSettings.ProductName.toLowerCase() === 'fit' ? `${brandSettings.ProductName.toUpperCase()}` : `${brandSettings.ProductName}`} Mastercard. At this time, a credit offer is not available for the following reason(s):</strong>
                                </p>
                            </div>
                            <div className="col-12 mt-2">
                                <p className="decline-reason gbresponse text-center" style={{ backgroundColor: "#f0f1f1" }}>
                                    {Parser(getDeclinedMessage())}
                                    {
                                        paymentClick && (
                                            <>
                                                You may click <a href={redirectLink} className='redirect_pendfee' target="_blank" rel="noopener noreferrer">here</a> to pay for the processing fee.
                                            </>
                                            
                                        )
                                    }
                                </p>
                                {
                                    !showOnDeclined ?
                                        <p className='text-left mb-0 check-email'>
                                            <strong>
                                                Please check your email for a message from <span><u>{getNoReplyEmail()}</u></span>. <br/>You may need to review your junk email for this message.
                                            </strong>
                                        </p>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {(setting.grandBankDeclined === true || (setting.DeclineOffers !== null && setting.DeclineOffers.some(offer => offer.Name === "BMGDeclineOffer"))) &&
                    <div className="row" style={{ backgroundColor: "#f0f1f1" }}>
                        <div className="col-12">
                            <p className="good-news">
                                Good news! There may be another option available for you...
                            </p>
                        </div>
                    </div>
                }

                {
                    (setting.grandBankDeclined === true) ? <GrandBankAdd /> : <></>
                }

                {(setting.DeclineOffers !== null && setting.DeclineOffers.some(offer => offer.Name === "BMGDeclineOffer")) && <OtherOptions />}

                <div className="row text-center">
                <div className="col-lg-12 reminder" style={{textAlign: "left", border: "1px solid #c9cdd5", boxShadow : "rgb(0 0 0 / 30%) 4px 4px", MarginTop: "0px !important"}}>
                    <small style={{lineHeight: "normal", textDecoration: "underline"}}>
                        The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion,
                        national origin, sex, marital status, age (provided that the applicant has the capacity to enter into a binding contract);
                        because all or part of the applicant's income is derived from a public assistance program; or because the applicant has in good faith
                        exercised his/her right under the Consumer Credit Protection Act. The federal agency that administers the compliance with this law concerning
                        this creditor is the Division of Depositor and Consumer Protection, National Center for Consumer and Depositor Assistance, Federal Deposit Insurance Corporation,
                        1100 Walnut Street, Box #11, Kansas City, MO 64106.
                    </small>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Declined2;