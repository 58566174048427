import React from 'react'
import { useStoreState } from 'easy-peasy';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function DisclaimerModal({props}) {

    //easy-peasy state declarations
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);

    return (
        <>
            <Modal size="lg" show={props.showDisclaimer} onHide={() => props.setShowDisclaimer(false)}>
                <Modal.Header>
                    <Modal.Title>Bank Account Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        How do we use your information?
                    </h4>
                    <br/>
                    <p>
                        We only use the information to evaluate your application and qualify you for a potential line of credit. We do not 
                        store your credentials in our system. We do not share your information with anyone else.
                    </p>
                    <p>
                        To complete the application and accept the offer you need to link your bank account information online. You can 
                        verify your bank account electronically through this interface. Doing so will complete your application process and you will hear back in as little as 60 seconds.
                    </p>
                    <p style={{color: "#008cba"}}>
                        <FontAwesomeIcon icon={faLock} />&nbsp;
                        This is a secure site.
                    </p>
                    <br/>
                    <h4>
                        Ways we protect you and your information:
                    </h4>
                    <br/>
                    <p>
                        We use 256-bit encryption technology to protect your personal information when you’re using our site or apps.
                        You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and
                        you see a lock symbol in your web browser.
                    </p>
                    <p>
                        <a href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">See Privacy Policy</a>
                    </p>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" style={{ backgroundColor: brandSettings.Secondary, minWidth: "200px" }} onClick={() => props.setShowDisclaimer(false)}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DisclaimerModal